/** @format */

/* eslint-disable react/display-name */

import React from 'react';
import { TabPane, Tab } from 'semantic-ui-react';

import { DevicesImport, DevicesExport } from '../../containers';

const DevicesMassiveOperations = () => {
  const panes = [
    {
      menuItem: 'Import',
      render: () => (
        <TabPane>
          <DevicesImport />
        </TabPane>
      ),
    },
    {
      menuItem: 'Export',
      render: () => (
        <TabPane>
          <DevicesExport />
        </TabPane>
      ),
    },
  ];

  return (
    <>
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
    </>
  );
};

export { DevicesMassiveOperations };
