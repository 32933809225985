/** @format */

import axios from 'axios';

import { API_BASE_URL } from '../../../lib/variables';
import { getCurrentUserTokenWithoutBearer } from '../../../lib/auth';

export const downloadCsv = async (id, token) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/api/v2/manager/device/export/${id}/download`,
      {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${getCurrentUserTokenWithoutBearer()}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
