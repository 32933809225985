/** @format */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Divider, Header } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

import { AccessTokensTable } from '../AccessTokensTable';
import { AccessTokenCreate } from '../AccessTokenCreate';

import { isAccessToken } from '../../lib/auth';

const AccessTokensRead = ({ disableButton = true }) => {
  const [addToken, setAddToken] = useState(false);
  const [personalToken, setPersonalToken] = useState(false);

  const add = () => {
    setAddToken(true);
  };

  const cancel = () => {
    setAddToken(false);
  };

  const back = () => {
    window.history.back();
  };

  useEffect(() => {
    if (isAccessToken()) {
      setPersonalToken(true);
    } else {
      setPersonalToken(false);
    }
  }, []);

  return (
    <>
      <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
        Access Tokens
      </Header>

      {!addToken && !personalToken && (
        <div style={{ float: 'right' }}>
          {!disableButton && (
            <Button
              as={Link}
              key="back"
              compact
              icon
              color="red"
              labelPosition="left"
              onClick={back}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="icon"
                style={{ padding: '0.4em' }}
              />
              Back
            </Button>
          )}
          <Button
            key="AddAccessTokens"
            compact
            icon
            color="red"
            labelPosition="left"
            to={`#`}
            onClick={add}
          >
            <FontAwesomeIcon
              icon="plus-circle"
              className="icon"
              style={{ padding: '0.4em' }}
            />
            Add Access token
          </Button>
        </div>
      )}

      <Divider clearing />

      {!addToken && <AccessTokensTable />}

      {addToken && <AccessTokenCreate onCancel={cancel} />}
    </>
  );
};

AccessTokensRead.propTypes = {
  disableButton: PropTypes.bool,
};

export { AccessTokensRead };
