/** @format */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useInterval } from '../Hooks';
import { fetchDeviceManagerExportList } from '../../../actions/deviceManager';
import { DeviceExportListTable } from './DeviceExportListTable';

const DevicesExport = () => {
  const [items, setItems] = useState([]);
  const [delay, setDelay] = useState(5000);

  const {
    listExports: list = { rows: [] },
    listExports: { offset = 0, limit = 10, size = null },
  } = useSelector(state => state.deviceManager);

  const dispatch = useDispatch();

  useInterval(() => {
    dispatch(
      fetchDeviceManagerExportList({
        offset,
        limit,
      }),
    );
  }, delay);

  useEffect(() => {
    dispatch(
      fetchDeviceManagerExportList({
        offset: list.offset,
        limit: list.limit,
      }),
    );

    return () => {
      return () => setDelay(null);
    };
  }, []);

  useEffect(() => {
    if (list && 'rows' in list && list.rows.length) setItems(list.rows);
  }, [list]);

  const onPageChange = (e, { activePage }) => {
    dispatch(
      fetchDeviceManagerExportList({
        offset: (activePage - 1) * limit,
        limit,
      }),
    );
  };

  return (
    <>
      {/* <pre>{JSON.stringify(items, 0, 2)}</pre> */}
      <DeviceExportListTable
        items={items}
        pagination={{
          offset,
          limit,
          size,
        }}
        onPageChange={onPageChange}
      />
    </>
  );
};

export { DevicesExport };
