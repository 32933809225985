/** @format */

import { CALL_API } from 'redux-api-middleware';

import { objectToQueryString } from '../lib/urls';
import { Endpoints as url } from '../lib/endpoints';
import { getCurrentUserToken } from '../lib/auth';

// ACTION TYPES
export const FETCH_DEVICE_MANAGER_IMPORT_LIST_REQUEST =
  'device-manager-import-list/FETCH_REQUEST';
export const FETCH_DEVICE_MANAGER_IMPORT_LIST_DEVICE_SUCCESS =
  'device-manager-import-list/FETCH_SUCCESS';
export const FETCH_DEVICE_MANAGER_IMPORT_LIST_DEVICE_FAILURE =
  'device-manager-import-list/FETCH_FAILURE';
export const FETCH_DEVICE_MANAGER_IMPORT_DETAIL_REQUEST =
  'device-manager-import-detail/FETCH_REQUEST';
export const FETCH_DEVICE_MANAGER_IMPORT_DETAIL_DEVICE_SUCCESS =
  'device-manager-import-detail/FETCH_SUCCESS';
export const FETCH_DEVICE_MANAGER_IMPORT_DETAIL_DEVICE_FAILURE =
  'device-manager-import-detail/FETCH_FAILURE';
export const FETCH_DEVICE_MANAGER_EXPORT_LIST_REQUEST =
  'device-manager-export-list/FETCH_REQUEST';
export const FETCH_DEVICE_MANAGER_EXPORT_LIST_DEVICE_SUCCESS =
  'device-manager-export-list/FETCH_SUCCESS';
export const FETCH_DEVICE_MANAGER_EXPORT_LIST_DEVICE_FAILURE =
  'device-manager-export-list/FETCH_FAILURE';
export const FETCH_DEVICE_MANAGER_EXPORT_DETAIL_REQUEST =
  'device-manager-export-detail/FETCH_REQUEST';
export const FETCH_DEVICE_MANAGER_EXPORT_DETAIL_DEVICE_SUCCESS =
  'device-manager-export-detail/FETCH_SUCCESS';
export const FETCH_DEVICE_MANAGER_EXPORT_DETAIL_DEVICE_FAILURE =
  'device-manager-export-detail/FETCH_FAILURE';
export const CREATE_DEVICE_MANAGER_EXPORT_REQUEST =
  'device-manager-export-create/CREATE_REQUEST';
export const CREATE_DEVICE_MANAGER_EXPORT_SUCCESS =
  'device-manager-export-create/CREATE_SUCCESS';
export const CREATE_DEVICE_MANAGER_EXPORT_FAILURE =
  'device-manager-export-create/CREATE_FAILURE';
export const DESTROY_DEVICE_MANAGER_EXPORT_SUCCESS =
  'device-manager-export-destroy/DESTROY_SUCCESS';
export const DESTROY_DEVICE_MANAGER_EXPORT_DETAIL_SUCCESS =
  'device-manager-export-detail-destroy/DESTROY_SUCCESS';

// ACTION CREATORS
export const fetchDeviceManagerImportList = (query = {}) => ({
  [CALL_API]: {
    endpoint: `${url.DeviceManagerImportList()}${
      Object.keys(query).length > 0 ? `?${objectToQueryString(query)}` : ''
    }`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_DEVICE_MANAGER_IMPORT_LIST_REQUEST,
      {
        type: FETCH_DEVICE_MANAGER_IMPORT_LIST_DEVICE_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_DEVICE_MANAGER_IMPORT_LIST_DEVICE_FAILURE,
        payload: (action, state, res) => {
          // eslint-disable-next-line no-console
          console.log(action, state, res);
          // history.push(`${PAGES_PATH}/devices`);
          // return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const fetchDeviceManagerImportDetail = (id, query = {}) => ({
  [CALL_API]: {
    endpoint: `${url.DeviceManagerImportDetail(id)}${
      Object.keys(query).length > 0 ? `?${objectToQueryString(query)}` : ''
    }`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_DEVICE_MANAGER_IMPORT_DETAIL_REQUEST,
      {
        type: FETCH_DEVICE_MANAGER_IMPORT_DETAIL_DEVICE_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_DEVICE_MANAGER_IMPORT_DETAIL_DEVICE_FAILURE,
        payload: (action, state, res) => {
          // eslint-disable-next-line no-console
          console.log(action, state, res);
          // history.push(`${PAGES_PATH}/devices`);
          // return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

// ACTION EXPORTS
export const fetchDeviceManagerExportList = (query = {}) => ({
  [CALL_API]: {
    endpoint: `${url.DeviceManagerExportList()}${
      Object.keys(query).length > 0 ? `?${objectToQueryString(query)}` : ''
    }`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_DEVICE_MANAGER_EXPORT_LIST_REQUEST,
      {
        type: FETCH_DEVICE_MANAGER_EXPORT_LIST_DEVICE_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_DEVICE_MANAGER_EXPORT_LIST_DEVICE_FAILURE,
        payload: (action, state, res) => {
          // eslint-disable-next-line no-console
          console.log(action, state, res);
          // history.push(`${PAGES_PATH}/devices`);
          // return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const fetchDeviceManagerExportDetail = (id, query = {}) => ({
  [CALL_API]: {
    endpoint: `${url.DeviceManagerExportDetail(id)}${
      Object.keys(query).length > 0 ? `?${objectToQueryString(query)}` : ''
    }`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_DEVICE_MANAGER_EXPORT_DETAIL_REQUEST,
      {
        type: FETCH_DEVICE_MANAGER_EXPORT_DETAIL_DEVICE_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_DEVICE_MANAGER_EXPORT_DETAIL_DEVICE_FAILURE,
        payload: (action, state, res) => {
          // eslint-disable-next-line no-console
          console.log(action, state, res);
          // history.push(`${PAGES_PATH}/devices`);
          // return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const createExportDevices = filters => ({
  [CALL_API]: {
    endpoint: url.DevicesExport(),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    body: JSON.stringify(filters),
    types: [
      CREATE_DEVICE_MANAGER_EXPORT_REQUEST,
      {
        type: CREATE_DEVICE_MANAGER_EXPORT_SUCCESS,
        payload: (action, state, res) =>
          res.json().then(json => {
            // history.push({ pathname: `${PAGES_PATH}/device/${json.id}` });
            return json;
          }),
      },
      {
        type: CREATE_DEVICE_MANAGER_EXPORT_FAILURE,
        payload: (action, state, res, err) => err,
        // res.json().then(errtoast => toastError(errtoast)),
      },
    ],
  },
});

export const destroyExportDevices = () => ({
  type: DESTROY_DEVICE_MANAGER_EXPORT_SUCCESS,
});

export const destroyExportDeviceDetail = () => ({
  type: DESTROY_DEVICE_MANAGER_EXPORT_DETAIL_SUCCESS,
});
