/**
 * /* eslint-disable no-console
 *
 * @format
 */

/** @format */

export const branch = process.env.REACT_APP_BRANCH || 'master';

export const ID_MEASUREMENT = process.env.REACT_APP_ID_MEASUREMENT || null;

const apiBaseProtocol = process.env.REACT_APP_API_PROTOCOL || 'http';
const apiBaseHostname = process.env.REACT_APP_API_BASE_URL || 'localhost';

export const API_BASE_URL = `${apiBaseProtocol}://${apiBaseHostname}`;

export const MQTT_CLIENT1_HOSTNAME =
  process.env.REACT_APP_MQTT_CLIENT1_HOSTNAME || 'localhost';

export const MQTT_CLIENT1_PORT =
  process.env.REACT_APP_MQTT_CLIENT1_PORT || 8083;

export const MQTT_CLIENT1_PROTOCOL =
  process.env.REACT_APP_MQTT_CLIENT1_PROTOCOL || 'mqtt';

export const MQTT_TOPIC_PREFIX =
  process.env.REACT_APP_MQTT_TOPIC_PREFIX || 'sub/v2';

export const API_PREFIX = `${process.env.REACT_APP_API_PREFIX || 'api'}`;
export const API_VERSION = `${process.env.REACT_APP_API_VERSION || 'v2'}`;
export const API_URL = `${API_BASE_URL}/${API_PREFIX}/${API_VERSION}`;
export const RNC_API_URL = `${API_BASE_URL}/${API_PREFIX}/v1/rnc`;
export const METRIC_URL = `${API_BASE_URL}/${API_PREFIX}/metric/v1`;
export const GWSTAT_URL = `${API_BASE_URL}/${API_PREFIX}/gwstat/v1`;
export const PAGES_PATH = '/dashboard';
export const GOOGLE_MAPS_API_KEY =
  'REACT_APP_GOOGLE_MAPS_API_KEY' in process.env &&
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    : null;
export const ENABLE_GEOLOCATION =
  'REACT_APP_ENABLE_GEOLOCATION' in process.env &&
  process.env.REACT_APP_ENABLE_GEOLOCATION === 'true'
    ? true
    : false;
export const ENABLE_RNC =
  'REACT_APP_ENABLE_RNC' in process.env &&
  process.env.REACT_APP_ENABLE_RNC === 'true'
    ? true
    : false;
export const ENABLE_POWEROFFHISTORY =
  'REACT_APP_ENABLE_POWEROFFHISTORY' in process.env &&
  process.env.REACT_APP_ENABLE_POWEROFFHISTORY === 'true'
    ? true
    : false;
export const ENABLE_GATEWAY_STATS_INFO =
  'REACT_APP_ENABLE_GATEWAY_STATS_INFO' in process.env &&
  process.env.REACT_APP_ENABLE_GATEWAY_STATS_INFO === 'true'
    ? true
    : false;

export const NEW_IMPORT_RELEASE_DATE =
  process.env.REACT_APP_NEW_IMPORT_RELEASE_DATE || '2024-09-26';
