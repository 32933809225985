/** @format */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import idx from 'idx';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
  Grid,
  Header,
  Icon,
  List,
} from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Axios from 'axios/index';
import { Endpoints as url } from '../../lib/endpoints';
import {
  userHasRole,
  getCurrentUserToken,
  isAccessToken,
} from '../../lib/auth';
import { ListItem } from '../../components';
import { destroyUser } from '../../actions/user';
import {
  fetchUserProfileSingle,
  destroyUserProfileSingle,
} from '../../actions/userProfileSingle';
import { PAGES_PATH } from '../../lib/variables';
import { toastError } from '../../lib/utilities';

const mapStateToProps = state => ({
  userProfileSingle: state.userProfileSingle,
});

const mapDispatchToProps = dispatch => ({
  fetchUserProfileSingle: id => {
    dispatch(fetchUserProfileSingle(id));
  },
  destroyUser: () => {
    dispatch(destroyUser());
  },
  destroyUserProfileSingle: () => {
    dispatch(destroyUserProfileSingle());
  },
});

class UserShow extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      user: {},
      isAccessToken: isAccessToken(),
    };
    this.goBack = this.goBack.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const uid = this.props.match.params.uid ? this.props.match.params.uid : '';
    userHasRole('admin') ? this.axiosFetchUser(uid) : this.axiosFetchUser('');
  }

  componentWillUnmount() {
    this.props.destroyUser();
    this.props.destroyUserProfileSingle();
  }

  axiosFetchUser(uid) {
    Axios.get(url.User(uid), {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
    })
      .then(res => {
        const user = res.data;
        if (this.props.location.pathname.indexOf('profile') !== -1) {
          this.props.fetchUserProfileSingle();
        } else if (userHasRole('admin')) {
          this.props.fetchUserProfileSingle(user.UserProfileId);
        } else this.props.fetchUserProfileSingle();

        this.setState({ user });
      })
      .catch(err => toastError(err.response.data));
  }

  handleClose() {
    this.setState({ modalOpen: false, username: null, check: null });
  }

  handleDeleteModal() {
    this.setState({ modalOpen: true });
  }

  handleDelete() {
    const { user, deleteUser } = this.props;

    deleteUser(user.id);
    this.setState({ modalOpen: false, check: null });
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const { user } = this.state;
    const { userProfileSingle } = this.props;

    return (
      <Fragment>
        <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
          {idx(user, _ => _.username)}
        </Header>
        <div style={{ float: 'right' }}>
          <Button
            as={Link}
            key="edit"
            compact
            icon
            color="red"
            labelPosition="left"
            to={`${PAGES_PATH}/user/${user.id}/edit`}
          >
            <FontAwesomeIcon
              icon="edit"
              className="icon"
              style={{ padding: '0.4em' }}
            />
            Edit
          </Button>
          {!this.state.isAccessToken && (
            <Button
              as={Link}
              key="AccessTokens"
              compact
              icon
              color="red"
              labelPosition="left"
              to={`${PAGES_PATH}/user/access-tokens`}
            >
              <FontAwesomeIcon
                icon="list"
                className="icon"
                style={{ padding: '0.4em' }}
              />
              Access tokens
            </Button>
          )}
          {this.props.location.state === false ? (
            <>
              {' '}
              {userHasRole('admin') && (
                <Button
                  as={Link}
                  key="list"
                  compact
                  icon
                  color="red"
                  labelPosition="left"
                  to={`${PAGES_PATH}/users`}
                >
                  <FontAwesomeIcon
                    icon="list"
                    className="icon"
                    style={{ padding: '0.4em' }}
                  />
                  List
                </Button>
              )}
            </>
          ) : (
            <Button
              as={Link}
              key="back"
              compact
              icon
              color="red"
              labelPosition="left"
              onClick={this.goBack}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="icon"
                style={{ padding: '0.4em' }}
              />
              Back
            </Button>
          )}
        </div>
        <Divider clearing />
        <Grid columns={2} stackable style={{ marginTop: '5em' }}>
          <Grid.Column>
            <Card fluid>
              <Card.Content>
                <Card.Header>Personal Info</Card.Header>
                <Divider />
                <Card.Description>
                  <List verticalAlign="middle" divided selection>
                    <ListItem
                      label="firstname"
                      content={idx(user, _ => _.firstname)}
                      copy={true}
                    />
                    <ListItem
                      label="lastname"
                      content={idx(user, _ => _.lastname)}
                      copy={true}
                    />
                    <ListItem
                      label="email"
                      content={idx(user, _ => (
                        <a href={`mailto:${_.email}`}>{_.email}</a>
                      ))}
                      copy={true}
                    />
                    <ListItem
                      label="company"
                      content={idx(user, _ => _.company)}
                      copy={true}
                    />
                    <ListItem
                      label="status"
                      content={idx(user, _ =>
                        _.status ? (
                          <Icon color="green" name="check" size="large" />
                        ) : (
                          <Icon color="red" name="cancel" size="large" />
                        ),
                      )}
                      copy={false}
                    />
                  </List>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid>
              <Card.Content>
                <Card.Header>Profile</Card.Header>
                <Divider />
                <Card.Description>
                  <List verticalAlign="middle" divided selection>
                    <ListItem
                      label="ID"
                      content={idx(userProfileSingle, _ => _.id)}
                      copy={true}
                    />
                    <ListItem
                      label="profile"
                      content={idx(userProfileSingle, _ => _.label)}
                      copy={true}
                    />
                    <ListItem
                      label="role"
                      content={idx(userProfileSingle, _ => _.role)}
                      copy={true}
                    />
                    {!userHasRole('admin') && (
                      <Fragment>
                        <ListItem
                          label="max devices"
                          content={`${
                            idx(userProfileSingle, _ => _.maxdevices)
                              ? idx(userProfileSingle, _ => _.maxdevices)
                              : 'unlimited'
                          }`}
                          copy={true}
                        />

                        <ListItem
                          label="max gateways"
                          content={`${
                            idx(userProfileSingle, _ => _.maxgateways)
                              ? idx(userProfileSingle, _ => _.maxgateways)
                              : 'unlimited'
                          }`}
                          copy={true}
                        />
                        <ListItem
                          label="max multicast groups"
                          content={`${
                            idx(userProfileSingle, _ => _.maxgroups)
                              ? idx(userProfileSingle, _ => _.maxgroups)
                              : 'unlimited'
                          }`}
                          copy={true}
                        />
                        <ListItem
                          label="max uplink"
                          content={
                            idx(userProfileSingle, _ => _.maxuplink)
                              ? idx(userProfileSingle, _ => _.maxuplink)
                              : 'unlimited'
                          }
                          copy={true}
                        />
                        <ListItem
                          label="max downlink"
                          content={
                            idx(userProfileSingle, _ => _.maxdownlink)
                              ? idx(userProfileSingle, _ => _.maxdownlink)
                              : 'unlimited'
                          }
                          copy={true}
                        />
                        <ListItem
                          label="cache life"
                          content={
                            idx(userProfileSingle, _ => _.cacheperiod)
                              ? idx(userProfileSingle, _ => _.cacheperiod)
                              : 'unlimited'
                          }
                          copy={true}
                        />
                        <ListItem
                          label="profile life"
                          content={
                            idx(userProfileSingle, _ => _.life)
                              ? idx(userProfileSingle, _ => _.life)
                              : 'unlimited'
                          }
                          copy={true}
                        />
                      </Fragment>
                    )}
                  </List>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
      </Fragment>
    );
  }
}

UserShow.propTypes = {
  match: PropTypes.object,
  fetchUserProfileSingle: PropTypes.func,
  deleteUser: PropTypes.func,
  destroyUser: PropTypes.func,
  destroyUserProfileSingle: PropTypes.func,
  location: PropTypes.object,
  user: PropTypes.object,
  history: PropTypes.object,
  userProfileSingle: PropTypes.object,
};

const UserRead = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserShow),
);

export { UserRead };
