/** @format */

import React, { Fragment } from 'react';
import { Divider, Item, Header } from 'semantic-ui-react';

import { branch } from '../../lib/variables';

const Docs = () => (
  <Fragment>
    <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
      Documentations
    </Header>
    <Divider clearing />
    <Item.Group divided>
      {branch !== 'smat' && (
        <Item>
          <Item.Image src={'/assets/images/docv1.png'} size="big" />

          <Item.Content>
            <Item.Header>
              <a href="https://doc.smartcitylab.io/v1" target="blank">
                API v1
              </a>
            </Item.Header>
            <Item.Description>
              <span className="url">
                <a href="https://doc.smartcitylab.io/v1" target="blank">
                  https://doc.smartcitylab.io/v1
                </a>
              </span>
            </Item.Description>
          </Item.Content>
        </Item>
      )}

      {branch === 'smat' && (
        <>
          <Item>
            <Item.Image src={'/assets/images/docv1.png'} size="big" />

            <Item.Content>
              <Item.Header>
                <a href="https://doc.smartcitylab.io/smat" target="blank">
                  MQTT v2
                </a>
              </Item.Header>
              <Item.Description>
                <span className="url">
                  <a href="https://doc.smartcitylab.io/smat" target="blank">
                    https://doc.smartcitylab.io/smat
                  </a>
                </span>
              </Item.Description>
            </Item.Content>
          </Item>
        </>
      )}

      <Item>
        <Item.Image src={'/assets/images/docv2.png'} size="big" />

        <Item.Content>
          <Item.Header>
            <a href="https://doc.v2.smartcitylab.io" target="blank">
              API v2
            </a>
          </Item.Header>
          <Item.Description>
            <span className="url">
              <a href="https://doc.v2.smartcitylab.io" target="blank">
                https://doc.v2.smartcitylab.io
              </a>
            </span>
          </Item.Description>
        </Item.Content>
      </Item>
    </Item.Group>
  </Fragment>
);

export { Docs };
