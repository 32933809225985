/** @format */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Container,
  Icon,
  Pagination,
  Popup,
  Table,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
} from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactJson from 'react-json-view';

import { ProgressPercent } from '../Utils/Utils';
import { userHasRole } from '../../../lib/auth';

import { downloadCsv } from '../Utils/Download';

import './DeviceExportListTable.css';

const DeviceExportListTable = ({
  items = [],
  pagination = { page: 1, offset: 10, size: null },
  onPageChange = () => {},
}) => {
  const notify = description => {
    toast({
      title: 'COPIED TO CLIPBOARD',
      description,
      color: 'teal',
      type: 'success',
      icon: 'copy',
    });
  };

  const [download, setDownload] = useState(null);
  const [dataInCSV, setDataInCSV] = useState(null);

  const handleDownload = id => {
    setDownload(id);
    downloadCsv(id).then(res => setDataInCSV(res));
  };

  useEffect(() => {
    if (dataInCSV) {
      const url = window.URL.createObjectURL(
        new Blob([dataInCSV], { type: 'text/csv' }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${download}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      setDownload(null);
      setDataInCSV(null);
    }
  }, [dataInCSV]);

  return (
    <>
      <Table celled>
        <TableHeader>
          <TableRow>
            <TableHeaderCell width={3} />
            <TableHeaderCell width={4}>Status</TableHeaderCell>
            {userHasRole('admin') && <TableHeaderCell>User</TableHeaderCell>}
            <TableHeaderCell>Total</TableHeaderCell>
            <TableHeaderCell width={2}>Params</TableHeaderCell>
            <TableHeaderCell width={3}>Duration</TableHeaderCell>
            <TableHeaderCell width={1} />
          </TableRow>
        </TableHeader>

        <TableBody>
          {items.map(item => {
            return (
              <TableRow key={item.id}>
                <TableCell>
                  <>
                    <Popup
                      content={item.message || 'Everything ok'}
                      trigger={<Icon name={'info circle'} />}
                    />
                    <span
                      className="list-item-id"
                      onClick={() => notify(item.id)}
                    >
                      <CopyToClipboard text={item.id}>
                        <span>{item.id}</span>
                      </CopyToClipboard>
                    </span>
                  </>
                </TableCell>
                <TableCell textAlign="center">
                  <ProgressPercent
                    status={item.status}
                    total={item.total}
                    success={item.total}
                    error={0}
                  />
                </TableCell>
                {userHasRole('admin') && (
                  <TableCell textAlign="left">{item.username}</TableCell>
                )}
                <TableCell>{item.total}</TableCell>
                <TableCell>
                  <span className="list-item-params">
                    <ReactJson
                      src={item.params}
                      name={null}
                      collapsed={true}
                      displayArrayKey={false}
                      displayDataTypes={false}
                      displayObjectSize={false}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  <span>
                    {moment
                      .utc(moment(item.updatedAt).diff(moment(item.createdAt)))
                      .format('HH:mm:ss.SSS')}
                  </span>
                  <br />
                  <span className="list-item-createdat">
                    created @{' '}
                    {moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                  </span>
                </TableCell>
                <TableCell textAlign="center">
                  <Button
                    basic
                    icon="download"
                    loading={download === item.id}
                    onClick={() => handleDownload(item.id)}
                    disabled={
                      (item.status !== 'completed' && !!download) ||
                      item.total === 0 ||
                      ['error', 'pending'].includes(item.status)
                    }
                  />
                </TableCell>
              </TableRow>
            );
          })}
          {items.length <= 0 && (
            <TableRow>
              <TableCell colSpan={7} textAlign="center">
                <span style={{ color: 'gray', fontStyle: 'italic' }}>
                  No data
                </span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {items.length > 0 && (
        <Container textAlign="center">
          <Pagination
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            activePage={parseInt(pagination.offset / pagination.limit, 10) + 1}
            totalPages={Math.ceil((pagination.size || 0) / pagination.limit)}
            onPageChange={onPageChange}
          />
        </Container>
      )}
    </>
  );
};

DeviceExportListTable.propTypes = {
  items: PropTypes.array,
  pagination: PropTypes.object,
  onPageChange: PropTypes.func,
};

export { DeviceExportListTable };
